





































import {Vue, Component} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {InputType} from "@/components/common/InputType";
import W9CRPersonDTO from "@/dto/request/w9cr/W9CRPersonDTO";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";
import W9CRPersonData from "@/components/request/w9cr/W9CRPersonData.vue";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";

const AppModule = namespace("App");

@Component({
  computed: {
    W9CRContractorStatus() {
      return W9CRContractorStatus
    },
    FormFiller() {
      return FormFiller
    },
    InputType() {
      return InputType
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {PortalInput, AutoComplete, PhoneInput, SingleFileHolder, W9CRPersonData}
})
export default class PersonW9CR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  contractor: W9CRContractorDTO | null = null;
  emailProvided = false;

  customerHasForm: boolean | null = null;

  mounted(){
    this.loadRequest();
  }

  loadRequest(){
    this.startLoading();
    W9CRService.getPublicContractor(this.id).then(
        ok => {
          this.contractor = ok.data;
          this.emailProvided = !!ok.data.email;
          this.customerHasForm = ok.data.customerHasForm;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  get id(){
    return this.$route.params.id;
  }

  hasForm(){
    this.contractor!.customerHasForm = true;
    this.customerHasForm = true;
  }

  noForm(){
    this.contractor!.customerHasForm = false;
    this.customerHasForm = false;
  }

  w9Uploaded() {
    if(this.contractor){
      this.contractor.status = W9CRContractorStatus.DOCUMENTS_PROVIDED;
      this.startLoading();
      W9CRService.saveContractorByContractor(this.$route.params.id, this.contractor).then(
          ok => {
            this.stopLoading();
            console.log("OK")
          },
          err => {
            console.log(JSON.stringify(err))
            if(this.contractor){
              this.contractor.status = W9CRContractorStatus.FILLING_BY_CONTRACTOR;
            }
            this.stopLoading();
          }
      )
    }
  }


}
